












































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo61',
})
export default class Logo61 extends Vue {}
